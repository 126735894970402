import boiler from '@boiler';
import Cookies from 'js-cookie';
import App, { COOKIE_SLOTS } from './App';
import i18n from './i18n';
import store from './store';

const mapInputsToState = (json) => {
    // Pass labels to i18n
    i18n.set(json.labels);

    return {
        store: {
            ...json.store,
            performanceAccepted: (Cookies.get(COOKIE_SLOTS.PERFORMANCE) === 'true') || false,
            advertisingAccepted: (Cookies.get(COOKIE_SLOTS.ADVERTISING) === 'true') || false,
        },
    };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'cookie-notice',
        { store },
        mapInputsToState,
    );
}());
