const i18n = (k, returnKeyAsFallback = true) => {
    if (!k) return '';
    const key = k.replace(/-/g, '_');
    let label = null;
    if (i18n.labels) {
        label = i18n.labels[key];
        if (!label && returnKeyAsFallback) label = key;
    }
    return label;
};

i18n.set = labels => i18n.labels = ({ ...i18n.labels, ...labels });

export default i18n;

// Set default labels
i18n.labels = {
    get_a_better_experience: 'Get a better experience thanks to cookies',
    essential_and_functional: 'Essential and functional cookies:',
    essential_and_functional_info: 'These are necessary, respectively, to allow navigation on our website and provide the services you will request ("minimal cookies").',
    additional_cookies: 'Additional cookies',
    performance: 'Performance',
    performance_info: 'cookies collecting statistics about traffic and users\' behavior on our or third party websites',
    advertising: 'Advertising/targeting',
    advertising_info: 'cookies used to deliver adverts on our or third party websites more relevant to you and your interests, as well as to measure the effectiveness of advertising campaigns',
    reject_all_cookies: 'Reject all cookies',
    accept_all_cookies: 'Accept all cookies',
    accept_changes: 'Save changes',
    to_learn_more: 'To learn more about our cookies, visit our {cookie_notice}.',
    cookie_notice: 'Cookie Notice',
};
