import { PrimaryButton } from '@common/controls/Button';
import React from 'react';
import i18n from './i18n';
import * as Styles from './styles';

type PropsType = {
    performanceAccepted: boolean;
    advertisingAccepted: boolean;
    hasUnsavedChanges: boolean;
    togglePerformance: () => void;
    toggleAdvertising: () => void;
    rejectAllCookies: () => void;
    acceptAllCookies: () => void;
    saveChanges: () => void;
};

const AsideView = (props: PropsType): JSX.Element => (
    <Styles.AsideViewStyleContainer>
        <h2>{i18n('get_a_better_experience')}</h2>
        <hr />
        <div className="form-group pt-2">
            <h5>{i18n('essential_and_functional')}</h5>
            {i18n('essential_and_functional_info')}
        </div>
        <div className="form-group cookie-consent">
            <h5>{i18n('additional_cookies')}</h5>
            <label htmlFor="performance-cookie">
                <input
                    type="checkbox"
                    id="performance-cookie"
                    checked={props.performanceAccepted}
                    onChange={props.togglePerformance}
                />
                <strong>{`${i18n('performance')}: `}</strong>
                {i18n('performance_info')}
            </label>
            <label htmlFor="advertising-cookie">
                <input
                    type="checkbox"
                    id="advertising-cookie"
                    checked={props.advertisingAccepted}
                    onChange={props.toggleAdvertising}
                />
                <strong>{`${i18n('advertising')}: `}</strong>
                {i18n('advertising_info')}
            </label>
        </div>
        <PrimaryButton className="btn btn-primary" onClick={props.rejectAllCookies}>
            {i18n('reject_all_cookies')}
        </PrimaryButton>
        {props.hasUnsavedChanges ? (
            <PrimaryButton className="btn btn-primary" onClick={props.saveChanges}>
                {i18n('accept_changes')}
            </PrimaryButton>
        ) : (
            <PrimaryButton className="btn btn-primary" onClick={props.acceptAllCookies}>
                {i18n('accept_all_cookies')}
            </PrimaryButton>
        )}
    </Styles.AsideViewStyleContainer>
);

export default AsideView;
